<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="tiposProveedorHeaders"
            class="elevation-1"
            :items="tiposProveedor"
            :search="search"
            item-key="tipoProvId"
          >
            <template v-slot:[`item.esInspector`]="{ item }">
              <v-icon
                v-if="item.esInspector"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
              <v-icon v-else small color="red" class="d-flex justify-center">
                {{ crossIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="openEditModal()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openEditModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar tipo de proveedor</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDelete(item.tipoProvId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar tipo de proveedor</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-if="editModal"
      v-model="editModal"
      max-width="40%"
      @keydown.esc="closeAndReload(false)"
      persistent
    >
      <EditTipoProveedor
        :tipoProveedor="itemToEdit"
        @closeAndReload="closeAndReload($event)"
      ></EditTipoProveedor>
    </v-dialog>

    <DeleteDialog
      :titleProp="titleDelete"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteTipoProveedor()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditTipoProveedor from "@/components/modules/proveedores/gestionProveedores/EditTipoProveedor.vue";

export default {
  name: "TiposProveedor",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda,
    EditTipoProveedor
  },
  data: () => ({
    title: "Tipos de proveedor",
    optionCode: enums.webSiteOptions.TIPOS_PROVEEDORES,
    checkIcon: enums.icons.CHECK_OUTLINE,
    crossIcon: enums.icons.CLOSE_OUTLINE,
    searchIcon: enums.icons.SEARCH,
    editModal: false,
    openDeleteDialog: false,
    routeToGo: "ConfiguracionTablasProveedores",
    tiposProveedor: [],
    search: "",
    idToDelete: null,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    loadingDeleteBtn: false,
    titleDelete: "¿Eliminar tipo de proveedor?",
    tiposProveedorHeaders: [
      {
        text: "Código",
        align: "start",
        value: "tipoProvCod",
        sortable: false
      },
      { text: "Nombre", value: "tipoProvNom", sortable: false },
      {
        text: "Es inspector",
        value: "esInspector",
        align: "center",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    itemToEdit: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadTiposProveedor();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTiposProveedor: "proveedores/getTiposProveedor",
      deleteTiposProveedor: "proveedores/deleteTiposProveedor",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_TIPO_PROVEEDOR:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_TIPO_PROVEEDOR:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_TIPO_PROVEEDOR:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadTiposProveedor() {
      const response = await this.getTiposProveedor();
      this.tiposProveedor = response;
    },
    openDelete(id) {
      this.idToDelete = id;
      this.openDeleteDialog = true;
    },
    async deleteTipoProveedor() {
      this.loadingDeleteBtn = true;
      try {
        const res = await this.deleteTiposProveedor(this.idToDelete);
        if (res.status === 200) {
          this.openDeleteDialog = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadTiposProveedor();
          this.loadingDeleteBtn = false;
        }
      } catch {}
      this.openDeleteDialog = false;
      this.loadingDeleteBtn = false;
    },
    openEditModal(item) {
      this.itemToEdit = item;
      this.editModal = true;
    },
    closeAndReload(reload) {
      this.editModal = false;
      if (reload) this.loadTiposProveedor();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
