<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model.trim="codigo"
                label="Código"
                dense
                hide-details="auto"
                outlined
                :rules="
                  codigo != null
                    ? rules.required.concat([
                        rules.requiredTrim(codigo),
                        rules.maxLength(codigo, 10)
                      ])
                    : []
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="py-0">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                dense
                hide-details="auto"
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 50)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="6" md="4" class="py-0">
              <v-subheader class="py-0 px-0">
                <v-switch
                  class="ml-1"
                  v-model="esInspector"
                  dense
                  outlined
                ></v-switch>
                Es inspector
              </v-subheader>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadigSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditTipoProveedor",
  props: {
    tipoProveedor: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: "Editar tipo de proveedor",
    rules: rules,
    codigo: null,
    nombre: null,
    esInspector: false,
    loadigSaveBtn: false
  }),
  created() {
    if (this.tipoProveedor != null) this.setTipoProveedor();
    else this.formEditTitle = "Nuevo tipo de proveedor";
  },
  methods: {
    ...mapActions({
      postTipoProveedor: "proveedores/postTipoProveedor",
      setAlert: "user/setAlert"
    }),
    async setTipoProveedor() {
      this.codigo = this.tipoProveedor.tipoProvCod;
      this.nombre = this.tipoProveedor.tipoProvNom;
      this.esInspector = this.tipoProveedor.esInspector;
    },
    async saveEdit() {
      this.loadigSaveBtn = true;
      const data = {
        tipoProvId:
          this.tipoProveedor != null ? this.tipoProveedor.tipoProvId : null,
        tipoProvCod: this.codigo,
        tipoProvNom: this.nombre,
        esInspector: this.esInspector
      };
      try {
        const res = await this.postTipoProveedor(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.$emit("closeAndReload", true);
        }
      } catch {}
      this.loadigSaveBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload", false);
    }
  }
};
</script>

<style></style>
