var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Código","dense":"","hide-details":"auto","outlined":"","rules":_vm.codigo != null
                  ? _vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.codigo),
                      _vm.rules.maxLength(_vm.codigo, 10)
                    ])
                  : []},model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"codigo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Nombre","dense":"","hide-details":"auto","outlined":"","rules":_vm.rules.required.concat([
                  _vm.rules.requiredTrim(_vm.nombre),
                  _vm.rules.maxLength(_vm.nombre, 50)
                ])},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombre"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-subheader',{staticClass:"py-0 px-0"},[_c('v-switch',{staticClass:"ml-1",attrs:{"dense":"","outlined":""},model:{value:(_vm.esInspector),callback:function ($$v) {_vm.esInspector=$$v},expression:"esInspector"}}),_vm._v(" Es inspector ")],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.loadigSaveBtn,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }